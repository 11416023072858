export const pageNotFoundTranslations = {
    en: {
        seoTitle: "404: Not found",
        heading: "Oops!",
        subHeading: "We can't find the page you're looking for.",
        linkText: "There's no place like home",
    },
    "en-GB": {
        seoTitle: "404: Not found",
        heading: "Oops!",
        subHeading: "We can't find the page you're looking for.",
        linkText: "There's no place like home",
    },
    fr: {
        seoTitle: "Erreur 404: Page introuvable",
        heading: "Oups!",
        subHeading: "La page que vous recherchez est introuvable.",
        linkText: "Retour à l'accueil",
    },
    de: {
        seoTitle: "404: Nicht gefunden",
        heading: "Hoppla!",
        subHeading: "Wir können die von Ihnen gesuchte Seite nicht finden.",
        linkText: "Zurück zu Talend",
    },
    it: {
        seoTitle: "404: Not found",
        heading: "Ops!",
        subHeading: "Non riusciamo a trovare la pagina che stai cercando.",
        linkText: "Vai alla home",
    },
    ja: {
        seoTitle: "404: Not found",
        heading: "申し訳ありません",
        subHeading: "お探しのページが見当たりません。",
        linkText: "ホームへ戻る",
    },
};
