import React from "react";
import { createI18nHelper, langMap } from "@v4/utils/i18nHelper";
import Layout from "../../components/layout/Layout";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Link from "@v4/gatsby-theme-talend/src/components/Link";
import { I18nProvider } from "@v4/utils/i18nContext";
import { pageNotFoundTranslations } from "../../translations/404";
import { getLangPrefixedPath } from "@v4/utils/pathPrefix";
import * as pageNotFoundStyles from "./404.module.css";

export const query = graphql`
    query($locale: String!) {
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
    }
`;

const NotFoundPage = ({ data, location, pageContext }) => {
    const { locale } = pageContext;
    
    const localeHelper = createI18nHelper(locale);
    const localePrefix = localeHelper.get("pathPrefix").from("langCode", locale)
    
    const hasMinimalNav = true;

    const langPrefixRegex = new RegExp(`^${localePrefix}/`);
    const translatedPaths = Object.keys(langMap).reduce(
        
        (acc, lang) => {
            return { ...acc, [lang]: getLangPrefixedPath(location.pathname.replace(langPrefixRegex, ""), lang) }},
        {}
    );

    return (
        <I18nProvider translatedPaths={translatedPaths} locale={locale}>
            <Layout headerMenu={data.contentfulMenu} footerMenu={{}} hasMinimalNav={hasMinimalNav}>
                <SEO title={pageNotFoundTranslations[locale].seoTitle} />
                <Helmet bodyAttributes={{ class: pageNotFoundStyles.bodyWrapper }} />
                <div className={pageNotFoundStyles.outerWrapper}>
                    <div className={pageNotFoundStyles.inner}>
                        <h1>{pageNotFoundTranslations[locale].heading}</h1>
                        <h2>{pageNotFoundTranslations[locale].subHeading}</h2>
                        <Link to={`/`}>{pageNotFoundTranslations[locale].linkText}</Link>
                    </div>
                </div>
            </Layout>
        </I18nProvider>
    );
};

export default NotFoundPage;
